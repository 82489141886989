import { Typography } from '@mui/material'
import QRCode from 'react-qr-code'
import styles from './BasicQrCode.module.scss'
import RichText from './RichText'

export default function BasicQrCode ({
  values = {
    instructionContent: 'Scan the QR code with your phone:',
    displayInstruction: true,
    qrValue: 'https://www.mytags.info',
  }
}) {

  return (
    <div className={`${styles.basicQrWrapper} ${!values.displayInstruction && styles.noInstruction}`}>
      {values.displayInstruction && (
        <RichText value={values.instructionContent} />
      )}
      <div className={styles.qrWrapper}>
        <QRCode size={140} value={values.qrValue} />
      </div>
    </div>
  )
}
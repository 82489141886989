import QRCode from 'react-qr-code'
import RichText from './RichText'
import styles from './WifiQrCode.module.scss'

export default function WifiQrCode ({
  values = {
    instructionContent: 'Scan the QR code with your phone:',
    displayInstruction: true,
    ssid: 'MyNetworkName',
    ssidHidden: false,
    authType: 'WPA',
    password: 'MyPassword',
  }
}) {

  return (
    <div className={`${styles.wifiQrWrapper} ${!values.displayInstruction && styles.noInstruction}`}>
      {values.displayInstruction && (
        <RichText value={values.instructionContent} />
      )}
      <div className={styles.qrWrapper}>
        <QRCode size={140} value={`WIFI:T:${values.authType};S:${values.ssid};P:${values.password};;`} />
      </div>
    </div>
  )
}
import { ImageOutlined } from '@mui/icons-material'
import styles from './Image.module.scss'

export default function Image ({ values }) {

  return (
    <div className={styles.imageContainer}>
      {values.imageUrl ? (
        <img src={values.imageUrl} alt={values.imageDescription} />
      ) : (
        <div className={styles.emptyPlaceholder}>
          <ImageOutlined sx={{ fontSize: '100px' }} />
        </div>
      )}
    </div>
  )
}
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useCallback } from "react";

export default function ReadOnlyContent(props) {
  const [editor] = useLexicalComposerContext();
  const ref = useCallback((rootElement) => {
    editor.setRootElement(rootElement);
  }, [editor]);

  return (<div ref={ref} />)
}
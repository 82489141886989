import { lexicalTheme } from 'components/dashboard/tag-editor/inputs/lexical/Theme';
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { HeadingNode, QuoteNode } from '@lexical/rich-text'
import { ListItemNode, ListNode } from '@lexical/list'
import { AutoLinkNode, LinkNode } from '@lexical/link'
import styles from './RichText.module.scss'
import ReadOnlyContent from 'components/dashboard/tag-editor/inputs/lexical/ReadOnlyContent';
import React from 'react';
import StateUpdaterPlugin from 'components/dashboard/tag-editor/inputs/lexical/StateUpdaterPlugin';

export default function RichText ({
  value = 'This is some intro text'
}) {
  const editorConfig = {
    theme: lexicalTheme,
    editable: false,
    editorState: JSON.stringify(value),
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      AutoLinkNode,
      LinkNode
    ]
  }

  return (
    <div
      className={styles.richText}
    >
      <LexicalComposer initialConfig={editorConfig}>
        <RichTextPlugin
          contentEditable={<ReadOnlyContent />}
        />
        <StateUpdaterPlugin newState={JSON.stringify(value)} />
      </LexicalComposer>
    </div>
  )
}
import styles from './InstructionsSteps.module.scss'

export default function InstructionsSteps ({ values }) {

  return (
    <ul className={styles.instructions}>
      {values.steps.map((step, index) => {
          return (
            <li key={`step-${index}`} className={styles.singleItem}>
              <div className={styles.labelContainer}>
                {step.label}
              </div>
              <div className={styles.contentContainer}>
                {step.content}
              </div>
            </li>
          )
        })}
    </ul>
  )
}
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { useEffect } from 'react';

export default function StateUpdaterPlugin({ newState }) {
  const [editor] = useLexicalComposerContext();
  
  useEffect(() => {
    if (newState && (newState !== '')) {
      const editorState = editor.parseEditorState(newState);
      editor.setEditorState(editorState);
    }
  }, [newState])

  return
}
import DOMPurify from 'isomorphic-dompurify';
import styles from './LabeledValues.module.scss'

export default function LabeledValues ({
  values = {
    items: [{ label: 'Label', content: 'Some content' }]
  }
}) {

  return (
    <ul className={styles.orderlyInfoWithLabels}>
      {values.items.map((item, index) => {
        return (
          <li
            key={`item-${index}`}
          >
            <span className={styles.label}>
              {item.label}
            </span>
            <span
              className={styles.value}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.content) }}
            />
          </li>
        )
      })}
    </ul>
  )
}
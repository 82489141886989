import BasicQrCode from '../basic-elements/BasicQrCode';
import InstructionsSteps from '../basic-elements/InstructionsSteps';
import LabeledValues from '../basic-elements/LabeledValues';
import RichText from '../basic-elements/RichText';
import Separator from '../basic-elements/Separator';
import WifiQrCode from '../basic-elements/WifiQrCode';
import Image from '../basic-elements/Image';

export const renderBasicFragment = ( fragment, index ) => {
  
  switch (fragment.type) {
    case 'richText':
      return <RichText key={`fragment-${index}`} value={fragment.values.value} />

    case 'labeledValues':
      return <LabeledValues key={`fragment-${index}`} values={fragment.values} />

    case 'wifiQrCode':
      return <WifiQrCode key={`fragment-${index}`} values={fragment.values} />

    case 'basicQrCode':
      return <BasicQrCode key={`fragment-${index}`} values={fragment.values} />

    case 'instructionsSteps':
      return <InstructionsSteps key={`fragment-${index}`} values={fragment.values} />

    case 'separator':
      return <Separator key={`fragment-${index}`} values={fragment.values} />

    case 'imageUpload':
      return <Image key={`fragment-${index}`} values={fragment.values} />
  
    default:
      break;
  }

}
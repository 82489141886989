import { BeachAccessOutlined, DirectionsBusOutlined, FormatListBulletedOutlined, HelpOutlineOutlined, LocalDiningOutlined, LocalParkingOutlined, LocalTaxiOutlined, NearMeOutlined, RecordVoiceOverOutlined, WbSunnyOutlined, WifiOutlined } from "@mui/icons-material";

export const availableTagIcons = [
  'contact',
  'list',
  'wifi',
  'parking',
  'info',
  'meal',
  'publicTransportation',
  'beach',
  'taxi',
  'directions',
  'weather',
]

export const tagIcons = {
  'contact': {
    name: 'Contact',
    icon: <RecordVoiceOverOutlined />,
  },
  'list': {
    name: 'List',
    icon: <FormatListBulletedOutlined />,
  },
  'wifi': {
    name: 'WiFi',
    icon: <WifiOutlined />,
  },
  'parking': {
    name: 'Parking',
    icon: <LocalParkingOutlined />,
  },
  'info': {
    name: 'Info',
    icon: <HelpOutlineOutlined />,
  },
  'meal': {
    name: 'Meal',
    icon: <LocalDiningOutlined />,
  },
  'publicTransportation': {
    name: 'Public Transportation',
    icon: <DirectionsBusOutlined />,
  },
  'beach': {
    name: 'Beach',
    icon: <BeachAccessOutlined />,
  },
  'taxi': {
    name: 'Taxi',
    icon: <LocalTaxiOutlined />,
  },
  'directions': {
    name: 'Directions',
    icon: <NearMeOutlined />,
  },
  'weather': {
    name: 'Weather',
    icon: <WbSunnyOutlined />,
  },
}